.emptyData {
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 227px;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.icon {
  margin-bottom: 5px;
}

.title {
  margin-bottom: 8px;
  font: var(--gilroy-Medium-18-24);
}

.description {
  font: var(--gilroy-Medium-14);
  color: var(--color-grey100);
}